import React from 'react';
import Layout from '../components/layout';
import { alternateLinks } from '../data/site-data';
import Hero from '../components/common/hero';
import CtaDeck from '../components/common/cta-deck';

const headMeta = {
  title: 'Abogado de Inmigración USA | Pandev Law',
  description:
    'Nuestra práctica migratoria está focalizada en la inmigración por vías de inversión y empleo, particularmente inversionistas por los tratados EB-5 y E-2, trabajadores especializados H-1B, y traslados intra-compañía L-1.',
  keywords:
    'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
  alternateLinks,
};

const schema = `{
  "@context": "https://schema.org/",
  "@type": "Product",
  "name": "Abogado de Inmigración USA | Pandev Law",
  "image": "https://www.pandevlaw.com/img/statue-liberty.jpg",
  "description": "Nuestra práctica migratoria está focalizada en la inmigración por vías de inversión y empleo, particularmente inversionistas por los tratados EB-5 y E-2, trabajadores especializados H-1B, y traslados intra-compañía L-1.",
  "brand": "Pandev Law",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "246"
  }
}`;

function Spanish({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema} lang="es-ES">
      <Hero title="Tu viaje a América comienza aquí" isTitleBgTransparent hideContactLink>
        <div className="text-bg-white">
          <p className="text-lead mb-5 mb-md-6">
            <strong>Pandev Law</strong>, LLC es un bufete de abogados especializado en
            aspectos corporativos y migratorios, comprometido en proveer representación de
            calidad excepcional. El lema, “tu viaje a América comienza aquí“ lo dice todo.
          </p>
          <p className="text-lead mb-5 mb-md-6">
            Nos especializamos en ayudar compañías internacionales e individuos a
            trasladarse a los Estados Unidos, al igual que al resto del mundo.
          </p>
        </div>
      </Hero>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6">
            <p className="text-body">
              Nuestra práctica migratoria está focalizada en la inmigración por vías de
              inversión y empleo, particularmente visa por inversión USA por convenios
              internacionales tales como la visa E-2, la cual es manejada por nuestro
              equipo de visa E-2 español. Residencia permanente por inversión, manejada
              por nuestro equipo de visa EB-5 español, y demás alternativas. Trabajadores
              especializados H-1B, y traslados intra-compañía para gerentes de empresas
              internacionales, los cuales pueden ser facilitados por medio de información
              sobre visa L-1. También ayudamos a reunir familias mediante el matrimonio,
              el noviazgo, y otras peticiones familiares. Poseemos vasta experiencia con
              el proceso de residencia permanente (Green Card) por medio de empleo,
              incluyendo certificaciones de labor PERM, exención por intereses nacionales
              EB-2, y peticiones EB-1 para gerentes de multinacionales e individuos de
              habilidad extraordinaria, incluyendo académicos, investigadores,
              científicos, artistas y atletas. También ayudamos a clientes a obtener
              ciudadanía o residencia permanente mediante inversiones en varios países en
              Europa y el Caribe.
              <br />
              <br />
              Nuestra práctica corporativa se especializa en aconsejar emprendedores,
              pequeñas y medianas empresas (PYMES) en formación y gestión, fusiones y
              adquisiciones, transacciones comerciales y financiamiento, incluyendo
              capital de riesgo. Aconsejamos clientes regularmente en transacciones
              internacionales y representamos compañías extranjeras buscando integrarse al
              mercado estadounidense. Ayudamos a nuestros clientes con planeación de
              impuestos y conformidad con las reglas sobre impuestos internacionales. A
              través de nuestra vasta red de profesionales, también asistimos a nuestros
              clientes con protección de propiedad intelectual y conformidad con las leyes
              de importe/exporte, incluyendo mitigación de alegadas violaciones a la ley
              de frontera. Nos esmeramos en hacer su expansión internacional un proceso
              fluido, para que usted pueda dedicar su tiempo y energía directamente a su
              negocio.
            </p>
          </div>
          <div className="col-lg-6">
            <p className="text-body mb-5">
              Antes de formar Pandev Law, LLC, nuestro abogado, Adrian Pandev, ejerció
              como abogado de juicio en el departamento de justicia de los Estados Unidos,
              en la oficina de litigación inmigratoria en Washington, DC. Como abogado de
              juicio, representó a diversas agencias gubernamentales – incluyendo el
              Departamento de Estado de los Estados Unidos (DOS), Aduanas y protección de
              fronteras (CBP), y el Departamento de Ciudadanía y Servicios Inmigratorios
              (USCIS) – En litigación ante las cortes del distrito federal y las cortes
              federales de apelación por todo Estados Unidos. Previamente, Adrian ocupó
              posiciones en el Departamento de Labor (DOL), la Corte migratioria de
              Atlanta, la oficina en Nueva York de una gran compañía internacional, un
              bufete de abogados especializados en migración tipo boutique en Washington,
              D.C., y el bufete de abogados de migración más grande del mundo.
              <br />
              <br />
              El equipo de Pandev Law especializado en Latinoamérica se enorgullece en
              representar individuos y compañías en transacciones de negocios
              internacionales, al igual que asuntos migratorios en los Estados Unidos.
              Nuestro equipo con enfoque en Latinoamérica ha ayudado a clientes de todos
              los países Latinoamericanos, y varios países del Caribe. Nuestro equipo es
              fluido en español y nos encantaría poder aconsejarle en su idioma nativo.
              <br />
              <br />
              Si desea contactarnos, por favor envíenos un correo electrónico a{' '}
              <a className="link-primary" href="mailto:admin@pandevlaw.com">
                admin@pandevlaw.com
              </a>{' '}
              o complete nuestro{' '}
              <a className="link-primary" href="/contact/">
                formulario de contacto
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <CtaDeck
        title="Solicite una consulta con un abogado hoy"
        buttonText="Hable con un abogado"
      />
    </Layout>
  );
}

export default Spanish;
